import React from 'react'
import styled from 'styled-components'
import { PostCategory } from './PostList'
import { LocaleConfig, useTranslations } from '../core'

interface CategoryBadgeProps {
    locale: LocaleConfig
    category: PostCategory
}

export const CategoryBadge = ({ locale, category }: CategoryBadgeProps) => {
    const { translate } = useTranslations(locale.locale)

    return (
        <Badge category={category}>{translate(`category_${category}`)}</Badge>
    )
}

const CATEGORY_COLORS: Record<PostCategory, string> = {
    media: '#F46A8C',
    updates: '#F08522',
    blog: '#3775D9',
}

const Badge = styled.div<{ category: PostCategory }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 106px;
    height: 22px;
    background-color: ${({ category }) => CATEGORY_COLORS[category]};
    color: white;
    border-radius: 44px;
    font-size: 10px;
    line-height: 10px;
    padding-top: 1px;
`
