import React from 'react'
import styled, { keyframes } from 'styled-components'
import { theme } from '../theme'
import { LocaleConfig } from '../core'
import { ClientList } from 'modules/clients/ClientList'

interface ClientListMarqueeProps {
    locale: LocaleConfig
}

export const ClientListMarquee = ({ locale }: ClientListMarqueeProps) => {
    return (
        <StyledClientListMarquee>
            <Marquee>
                <ClientList ClientComponent={Client} locale={locale} />
            </Marquee>
            <Marquee aria-hidden={true}>
                <ClientList ClientComponent={Client} locale={locale} />
            </Marquee>
        </StyledClientListMarquee>
    )
}

const marqueeAnimation = keyframes`
    0% { transform: translate3d(0, 0, 0); }
  100% { transform: translate3d(calc(-100% - var(--gap)), 0, 0); }
`

const Marquee = styled.div`
    display: flex;
    flex-shrink: 0;
    min-width: 100%;
    animation: ${marqueeAnimation} 75s linear infinite;
    gap: var(--gap);
`

const StyledClientListMarquee = styled.div`
    --gap: 1rem;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);

    @media (hover: hover) {
        &:hover {
            ${Marquee} {
                animation-play-state: paused;
            }
        }
    }
`

const Client = styled.img`
    max-width: 206px;
    max-height: 110px;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${theme.breakpoints.phone_only} {
        max-width: 118px;
        max-height: 62px;
    }
`
