import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { MDXProvider, MDXProviderComponents } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import {
    Layout,
    SEO,
    Container,
    SectionSpacer,
    useTranslations,
    ResponsiveVideo,
} from 'modules/core'
import { CategoryBadge } from './CategoryBadge'

// Add any components you wish to expose to MDX blog posts
const MDX_COMPONENTS: MDXProviderComponents = {
    ResponsiveVideo,
}

const PostLayout = (props: any) => {
    const { post, previous, next } = props.pageContext
    const { title, description, illustration, category, date, body } = post

    const { translate } = useTranslations(props.pageContext.localeConfig.locale)

    return (
        <Layout locale={props.pageContext.localeConfig} path={props.path}>
            <SEO
                title={title}
                description={description}
                featuredImage={illustration}
            />
            <PageHeader />
            <Container>
                <Illustration image={illustration.images.fallback.src} />
                <PostHeader>
                    <CategoryBadge
                        locale={props.pageContext.localeConfig}
                        category={category}
                    />
                    <Title>{title}</Title>
                    <Date>{date}</Date>
                </PostHeader>
                <MDXProvider components={MDX_COMPONENTS}>
                    <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
                <SectionSpacer />
                <Pagination>
                    {previous ? (
                        <PaginationLink to={`${previous.path}/`}>
                            &lt; {translate('previous_article')}
                        </PaginationLink>
                    ) : (
                        <span />
                    )}
                    {next ? (
                        <PaginationLink to={`${next.path}/`}>
                            {translate('next_article')}&nbsp;&gt;
                        </PaginationLink>
                    ) : (
                        <span />
                    )}
                </Pagination>
            </Container>
        </Layout>
    )
}

export default PostLayout

const PageHeader = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.colors.accent};
    height: 160px;
    z-index: -1;

    @media (max-width: 500px) {
        height: 72px;
    }
`

const PostHeader = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`

const Title = styled.h1`
    font-size: 32px;
    line-height: 48px;
    margin: 8px 0 0 0;
    ${({ theme }) => theme.typography_presets.bold};
`

const Date = styled.div`
    font-size: 14px;
    line-height: 21px;
`

const Illustration = styled.div<{ image: string }>`
    height: 482px;
    width: 100%;
    margin: -80px 0 32px 0;
    background-color: ${({ theme }) => theme.colors.accent};
    background-image: url('${({ image }) => image}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        height: 252px;
        margin: -80px -12px 32px -12px;
        width: calc(100% + 24px);
    }
`

const Pagination = styled.div`
    display: flex;
    justify-content: space-between;
`

const PaginationLink = styled(Link)`
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.invertedBackground};
    border-radius: 3px;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    white-space: nowrap;
    color: #4d4d4d;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.invertedBackground};
        color: ${({ theme }) => theme.colors.invertedText};
    }
`
