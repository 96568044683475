import React from 'react'
import styled from 'styled-components'
import { theme } from '../theme'
import { ClientList } from 'modules/clients/ClientList'
import { LocaleConfig } from 'modules/core'

interface ClientGridProps {
    locale?: LocaleConfig
}

// We supply a default locale because this
// component can be used in MDX files which
// we generally use for japanese-only content
const defaultLocale: LocaleConfig = {
    locale: 'ja',
    isDefault: true,
}

export const ClientGrid = ({ locale = defaultLocale }: ClientGridProps) => {
    return (
        <Clients>
            <ClientList locale={locale} ClientComponent={Client} />
        </Clients>
    )
}

const Clients = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const Client = styled.img`
    max-width: 280px;
    max-height: 154px;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${theme.breakpoints.phone_only} {
        width: 50%;
    }
`
