import React from 'react'
import styled from 'styled-components'
import { Container, LocaleConfig } from '../core'
import { ReadMoreButton } from './ReadMoreButton'
import { PostCategory } from './PostList'
import { CategoryBadge } from './CategoryBadge'

interface FeaturedPostProps {
    title: string
    description: string
    illustration: string
    category: PostCategory
    path: string
    locale: LocaleConfig
}

export const FeaturedPost = ({
    title,
    description,
    illustration,
    category,
    path,
    locale,
}: FeaturedPostProps) => {
    return (
        <Container>
            <Wrapper>
                <Content>
                    <div>
                        <CategoryBadge locale={locale} category={category} />
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                    </div>
                    <ReadMoreButton path={path} />
                </Content>
                <Illustration
                    image={illustration}
                    data-testid="blog-featuredpost-illustration"
                />
            </Wrapper>
        </Container>
    )
}

const Wrapper = styled.div`
    height: 378px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 500px) {
        width: calc(100% + 24px);
        margin: -36px -12px 0 -12px;
        height: auto;
        flex-direction: column-reverse;
    }
`

const Content = styled.div`
    height: 100%;
    width: 50%;
    background-color: ${({ theme }) => theme.colors.lightBackground};
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 500px) {
        width: 100%;
        height: auto;
        padding: 18px 16px;
        align-items: center;
    }
`

const Title = styled.h2`
    font-size: 26px;
    line-height: 39px;
    margin: 8px 0 0 0;
    ${({ theme }) => theme.typography_presets.bold};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        margin: 12px 0 16px 0;
    }
`

const Description = styled.div`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
`

const Illustration = styled.div<{ image: string }>`
    height: 100%;
    width: 50%;
    background-color: ${({ theme }) => theme.colors.accent};
    background-image: url('${({ image }) => image}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (max-width: 500px) {
        width: 100%;
        height: 180px;
    }
`
