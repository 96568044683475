import { useCallback, useState } from 'react'

export const usePagination = (initialIndex: number, count: number) => {
    const [index, setIndex] = useState(initialIndex)

    const hasPrevious = index > 0
    const handlePrevious = useCallback(() => {
        setIndex((index - 1 + count) % count)
    }, [setIndex, index, count])

    const hasNext = index < count - 1
    const handleNext = useCallback(() => {
        setIndex((index + 1) % count)
    }, [setIndex, index, count])

    return {
        index,
        setIndex,
        handlePrevious,
        handleNext,
        hasPrevious,
        hasNext,
    }
}
