import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { ContentSpacer, LocaleConfig } from '../core'
import { PostCard } from './PostCard'
import { PostsCollectionData, normalizePostsData } from './collection'

interface LatestPostProps {
    locale: LocaleConfig
}

export const LatestPost = ({ locale }: LatestPostProps) => {
    const data = useStaticQuery<PostsCollectionData>(graphql`
        query LatestPostsQuery {
            allMdx(
                filter: { fileAbsolutePath: { glob: "**/content/posts/*.mdx" } }
                sort: { fields: frontmatter___date, order: DESC }
                limit: 3
            ) {
                edges {
                    node {
                        frontmatter {
                            title
                            description
                            illustration
                            category
                            path
                        }
                    }
                }
            }
            allFile(filter: { relativePath: { glob: "posts/*.png" } }) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                height: 482
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    `)
    const latestPosts = normalizePostsData(data)

    return (
        <>
            <Container>
                {latestPosts.map((post) => (
                    <PostCard
                        key={post.path}
                        title={post.title}
                        illustration={post.illustration}
                        category={post.category}
                        path={post.path}
                        locale={locale}
                    />
                ))}
            </Container>
            <ContentSpacer />
        </>
    )
}

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`
