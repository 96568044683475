import {
    flattenEdgesNodes,
    getMatchingImageSrc,
    ImageFilesData,
} from '../core/graphql'
import { Post, PostCategory } from './PostList'

export interface PostsCollectionData {
    allMdx: {
        edges: {
            node: {
                frontmatter: {
                    title: string
                    description: string
                    illustration: string
                    category: PostCategory
                    path: string
                }
            }
        }[]
    }
    allFile: ImageFilesData
}

export const normalizePostsData = (data: PostsCollectionData): Post[] => {
    const images = flattenEdgesNodes(data.allFile)

    return flattenEdgesNodes(data.allMdx).map(({ frontmatter }) => ({
        ...frontmatter,
        illustration: getMatchingImageSrc(images, frontmatter.illustration)!,
        path: `/english-learning-blog${frontmatter.path}/`,
    }))
}
