import React from 'react'
import { graphql } from 'gatsby'
import { Toaster } from 'react-hot-toast'
import {
    Layout,
    SEO,
    PageHero,
    SectionSpacer,
    usePageSections,
    LocaleConfig,
    useTranslations,
} from 'modules/core'
import { ClientListMarquee, Clients } from 'modules/clients'
import { AboutCooori, Highlights } from 'modules/home'
import { useNewsToast } from '../modules/core/useNewsToast'
import {
    flattenEdgesNodes,
    getMatchingImageSrc,
    ImageFilesData,
} from '../modules/core/graphql'
import { CallToAction } from '../modules/core/CallToAction'
import { Blog } from '../modules/home/Blog'

type HomeSection = 'cooori' | 'clients' | 'blog'

export const query = graphql`
    query HomeQuery($locale: String) {
        allMarkdownRemark(
            filter: {
                frontmatter: { page: { eq: "home" }, locale: { eq: $locale } }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        section
                        title
                    }
                    html
                }
            }
        }
        allHomeYaml {
            edges {
                node {
                    title {
                        ja
                        en
                    }
                    subtitle {
                        ja
                        en
                    }
                    cta_label {
                        ja
                        en
                    }
                    slides {
                        image
                        alt {
                            en
                            ja
                        }
                    }
                }
            }
        }
        allFile(filter: { relativePath: { glob: "hero-*.jpg" } }) {
            edges {
                node {
                    name
                    extension
                    childImageSharp {
                        gatsbyImageData(
                            layout: FIXED
                            height: 528
                            quality: 100
                        )
                    }
                }
            }
        }
        allMdx(
            filter: {
                fileAbsolutePath: { glob: "**/posts/*.mdx" }
                frontmatter: { locale: { eq: $locale }, news: { eq: true } }
            }
            limit: 1
            sort: { fields: frontmatter___date, order: DESC }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        description
                        path
                    }
                }
            }
        }
    }
`

interface HomePageProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
    }
    data: {
        allMarkdownRemark: {
            edges: Array<{
                node: {
                    frontmatter: {
                        section: HomeSection
                        title: string
                    }
                    html: string
                }
            }>
        }
        allHomeYaml: {
            edges: Array<{
                node: {
                    title: { ja: string; en: string }
                    subtitle: { ja: string; en: string }
                    cta_label: { ja: string; en: string }
                    slides: Array<{
                        image: string
                        alt: { ja: string; en: string }
                    }>
                }
            }>
        }
        allFile: ImageFilesData
        allMdx: {
            edges: Array<{
                node: {
                    frontmatter: {
                        title: string
                        description: string
                        path: string
                    }
                }
            }>
        }
    }
}

const HomePage = ({ data, pageContext, path }: HomePageProps) => {
    const { localeConfig } = pageContext
    const { translate } = useTranslations(localeConfig.locale)

    const sections = usePageSections<HomeSection>(data)
    const hero = data.allHomeYaml.edges[0].node

    const allHeroImages = flattenEdgesNodes(data.allFile)
    const imageSlides = hero.slides.map((slide) => ({
        image: getMatchingImageSrc(allHeroImages, slide.image)!,
        alt: slide.alt[localeConfig.locale],
    }))

    const newsPost = data.allMdx.edges[0]?.node.frontmatter
    useNewsToast(newsPost)

    return (
        <Layout locale={localeConfig} path={path}>
            <SEO
                title={translate('page_index')}
                description={translate('meta_description_index')}
            />
            <PageHero
                title={hero.title[localeConfig.locale]}
                subtitle={hero.subtitle[localeConfig.locale]}
                ctaLabel={hero.cta_label[localeConfig.locale]}
                imageSlides={imageSlides}
            />
            <ClientListMarquee locale={localeConfig} />
            <SectionSpacer />
            <AboutCooori
                locale={localeConfig}
                title={sections.cooori.title}
                content={sections.cooori.content}
            />
            <SectionSpacer />
            <Highlights locale={localeConfig} />
            <SectionSpacer />
            <Blog
                locale={localeConfig}
                title={sections.blog.title}
                content={sections.blog.content}
            />
            <Toaster position="bottom-left" />
        </Layout>
    )
}

export default HomePage
