import React from 'react'
import { withPrefix, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import {
    Container,
    ContentSpacer,
    LocaleConfig,
    PageSubTitle,
} from 'modules/core'
import { breakpoints } from '../theme/breakpoints'
import { useScrollAnalytics } from 'modules/analytics/useScrollAnalytics'

interface AboutCoooriProps {
    locale: LocaleConfig
    title: string
    content: string
}

export interface CoooriPointsData {
    allCoooriPointsYaml: {
        edges: Array<{
            node: {
                id: string
                title: {
                    ja: string
                    en: string
                }
                subtitle: {
                    ja: string
                    en: string
                }
            }
        }>
    }
}

const multipleDevicesAltText = {
    en: '',
    ja: 'マルチデバイスに対応したAIの法人向けオンライン英語研修サービス',
}

export const AboutCooori = ({ locale, title, content }: AboutCoooriProps) => {
    const visibleRef = useScrollAnalytics('home_AboutCooori')

    const data: CoooriPointsData = useStaticQuery(graphql`
        query CoooriPointsQuery {
            allCoooriPointsYaml {
                edges {
                    node {
                        id: yamlId
                        title {
                            en
                            ja
                        }
                        subtitle {
                            en
                            ja
                        }
                    }
                }
            }
        }
    `)

    const points = data.allCoooriPointsYaml.edges.map((edge) => ({
        ...edge.node,
        title: edge.node.title[locale.locale],
        subtitle: edge.node.subtitle[locale.locale],
    }))

    return (
        <div ref={visibleRef}>
            <Container>
                <PageSubTitle>{title}</PageSubTitle>
                <ContentSpacer />
                <InnerContainer>
                    <Info>
                        <Content
                            dangerouslySetInnerHTML={{
                                __html: content,
                            }}
                        />
                        <ContentSpacer size="small" />
                        <Points>
                            {points.map((point) => (
                                <Point key={point.id}>
                                    <PointTitle>{point.title}</PointTitle>
                                    <PointSubtitle>
                                        {point.subtitle}
                                    </PointSubtitle>
                                </Point>
                            ))}
                        </Points>
                    </Info>
                    <MultipleDevices
                        src={`${withPrefix('/images/multiple_devices.png')}`}
                        alt={multipleDevicesAltText[locale.locale]}
                    />
                </InnerContainer>
            </Container>
        </div>
    )
}

const InnerContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;

    @media ${breakpoints.phone_only} {
        padding-bottom: 230px;
    }
`

const Info = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 50%;

    @media ${breakpoints.phone_only} {
        width: 100%;
    }
`

const Content = styled.div``

const Points = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const Point = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    background: #000;
    color: #fff;
    width: 140px;
    height: 140px;
    border-radius: 100%;
    white-space: pre-wrap;
    text-align: center;
    margin-right: 24px;

    &:last-child {
        margin-right: 0;
    }

    @media ${breakpoints.phone_only} {
        width: 130px;
        height: 130px;

        &:nth-child(2) {
            margin-right: 0;
        }
    }
`

const PointTitle = styled.div`
    font-size: 16px;
    font-family: ${({ theme }) => theme.typography.boldFontFamily};
    font-weight: ${({ theme }) => theme.typography.weights.bold};
    line-height: 24px;
    margin-bottom: 4px;
`

const PointSubtitle = styled.div`
    font-size: 10px;
    line-height: 13px;
`

const MultipleDevices = styled.img`
    position: absolute;
    top: -72px;
    right: 0;
    width: 467px;
    height: 393px;
    object-fit: contain;

    @media ${breakpoints.phone_only} {
        top: auto;
        bottom: -40px;
        z-index: -1;
    }
`
