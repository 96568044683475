import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { LocaleConfig } from '../core'
import { CategoryBadge } from './CategoryBadge'
import { PostCategory } from './PostList'

interface PostCardProps {
    title: string
    illustration: string
    category: PostCategory
    path: string
    locale: LocaleConfig
}

export const PostCard = ({
    title,
    illustration,
    category,
    path,
    locale,
}: PostCardProps) => (
    <Container to={path} data-testid="blog-postcard">
        <Header image={illustration} data-testid="blog-postcard-header" />
        <Content>
            <CategoryBadge locale={locale} category={category} />
            <Title>{title}</Title>
        </Content>
    </Container>
)

const Container = styled(Link)`
    height: 341px;
    border: 1px solid #dddddd;
    width: 31%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    text-decoration: none;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text};

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        margin-top: 0;
    }

    @media (max-width: 500px) {
        width: 100%;
        height: 232px;
        margin-bottom: 24px;
    }
`

const Header = styled.div<{ image: string }>`
    height: 185px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.accent};
    background-image: url('${({ image }) => image}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (max-width: 500px) {
        height: 122px;
    }
`

const Content = styled.div`
    flex: 1 1 auto;
    padding: 24px 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.lightBackground};

    @media (max-width: 500px) {
        padding: 18px 16px;
    }
`

const Title = styled.h3`
    font-size: 20px;
    margin: 16px 0 0 0;
    width: 100%;
    ${({ theme }) => theme.typography_presets.bold};

    @media (max-width: 500px) {
        font-size: 18px;
        margin: 8px 0 0 0;
    }
`
