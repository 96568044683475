import React from 'react'
import { useSpring, animated } from 'react-spring'
import { Link, graphql, useStaticQuery, withPrefix } from 'gatsby'
import styled, { css } from 'styled-components'
import { Container, LocaleConfig } from 'modules/core'
import {
    flattenEdgesNodes,
    getMatchingImageSrc,
    ImageFilesData,
} from 'modules/core/graphql'
import { breakpoints } from '../theme/breakpoints'
import { usePagination } from 'modules/ui/usePagination'
import { useScrollAnalytics } from 'modules/analytics/useScrollAnalytics'
import { logHighlightNavigationEvent } from 'modules/analytics/logHighlightNavigationEvent'

interface HighlightsProps {
    locale: LocaleConfig
}

export interface HighlightsData {
    allHighlightsYaml: {
        edges: {
            node: {
                id: string
                title: {
                    ja: string
                    en: string
                }
                content: {
                    ja: string
                    en: string
                }
                label: {
                    ja: string
                    en: string
                }
                background: string
                alt: {
                    ja: string
                    en: string
                }
                url: string
            }
        }[]
    }
    allFile: ImageFilesData
}

export const Highlights = ({ locale }: HighlightsProps) => {
    const visibleRef = useScrollAnalytics('home_Highlights')

    const data: HighlightsData = useStaticQuery(graphql`
        query HighlightsQuery {
            allHighlightsYaml {
                edges {
                    node {
                        id: yamlId
                        title {
                            en
                            ja
                        }
                        content {
                            en
                            ja
                        }
                        label {
                            en
                            ja
                        }
                        background
                        alt {
                            en
                            ja
                        }
                        url
                    }
                }
            }
            allFile(
                filter: { relativePath: { glob: "home/highlight-*.png" } }
            ) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                height: 485
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    `)

    const allHighlightImages = flattenEdgesNodes(data.allFile)
    const highlights = flattenEdgesNodes(data.allHighlightsYaml).map(
        (node) => ({
            id: node.id,
            title: node.title[locale.locale],
            content: node.content[locale.locale],
            label: node.label[locale.locale],
            background: getMatchingImageSrc(
                allHighlightImages,
                node.background
            )!,
            alt: node.alt[locale.locale],
            url: node.url,
        })
    )

    const {
        index,
        setIndex,
        handleNext,
        handlePrevious,
        hasNext,
        hasPrevious,
    } = usePagination(0, highlights.length)

    const animationProps = useSpring({
        transform: `translate3d(-${
            highlights.length > 0 ? (100 / highlights.length) * index : 0
        }%, 0, 0)`,
    })

    return (
        <div ref={visibleRef}>
            <StyledHighlights>
                <HighlightsContainer>
                    <HighlightsWrapper
                        style={{
                            width: `${highlights.length * 100}%`,
                            ...animationProps,
                        }}
                    >
                        {highlights.map(
                            ({
                                id,
                                title,
                                content,
                                label,
                                background,
                                alt,
                                url,
                            }) => (
                                <Highlight key={id}>
                                    <HighlightBackground
                                        src={background}
                                        alt={alt}
                                    />
                                    <Container>
                                        <HighlightBox>
                                            <HighlightTitle>
                                                {title}
                                            </HighlightTitle>
                                            <HighlightContent>
                                                {content}
                                            </HighlightContent>
                                            <HighlightLink
                                                to={`${url}/`}
                                                onClick={() =>
                                                    logHighlightNavigationEvent(
                                                        url
                                                    )
                                                }
                                            >
                                                {label}
                                            </HighlightLink>
                                        </HighlightBox>
                                    </Container>
                                </Highlight>
                            )
                        )}
                    </HighlightsWrapper>
                </HighlightsContainer>
                {hasPrevious && (
                    <NavigationArrowLeft onClick={handlePrevious}>
                        <img src={withPrefix('images/icons/caret_left.svg')} />
                    </NavigationArrowLeft>
                )}
                {hasNext && (
                    <NavigationArrowRight onClick={handleNext}>
                        <img src={withPrefix('images/icons/caret_right.svg')} />
                    </NavigationArrowRight>
                )}
                <NavigationPills>
                    {highlights.map(({ id }, highlightIdx) => (
                        <NavigationPill
                            key={id}
                            isActive={index === highlightIdx}
                            onClick={() => setIndex(highlightIdx)}
                        />
                    ))}
                </NavigationPills>
            </StyledHighlights>
        </div>
    )
}

const StyledHighlights = styled.div`
    position: relative;
`

const HighlightsContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
`

const HighlightsWrapper = styled(animated.div)`
    display: flex;
    height: 100%;

    @media ${breakpoints.phone_only} {
        width: 100% !important;
        transform: translate3d(0, 0, 0) !important;
        flex-direction: column;
    }
`

const Highlight = styled.div`
    position: relative;
    width: 100%;
    padding: 90px 0;

    @media ${breakpoints.phone_only} {
        padding: 0;
    }
`

const HighlightBackground = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;

    @media ${breakpoints.phone_only} {
        position: static;
        height: 200px;
    }
`

const HighlightBox = styled.div`
    position: relative;
    width: 406px;
    padding: 32px;
    background: #fff;
    border-radius: ${({ theme }) => theme.border_radius.small};
    box-shadow: ${({ theme }) => theme.shadows.medium};
    z-index: 1;
    white-space: pre-wrap;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: ${({ theme }) => theme.border_radius.small};
        box-shadow: ${({ theme }) => theme.shadows.large};
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    @media (hover: hover) {
        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }

    @media ${breakpoints.phone_only} {
        width: 100%;
        border-radius: 0;
        padding: 48px 12px 48px 12px;
        box-shadow: none;
    }
`

const HighlightTitle = styled.div`
    font-size: 20px;
    font-family: ${({ theme }) => theme.typography.boldFontFamily};
    font-weight: ${({ theme }) => theme.typography.weights.bold};
    line-height: 30px;
    margin-bottom: 8px;

    @media ${breakpoints.phone_only} {
        position: absolute;
        display: inline-block;
        top: -18px;
        left: 12px;
        background: white;
        padding: 0 16px;
        text-align: center;
        z-index: -1;
    }
`

const HighlightContent = styled.div`
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
`

const HighlightLink = styled(Link)`
    font-size: 14px;
    line-height: 21px;
    font-family: ${({ theme }) => theme.typography.boldFontFamily};
    font-weight: ${({ theme }) => theme.typography.weights.bold};
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: none;

    @media (hover: hover) {
        &:hover {
            text-decoration: underline;
        }
    }
`

const navigationArrowStyles = css`
    position: absolute;
    top: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text});
    background-color: white;
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    border-radius: 50%;
    transition: box-shadow 200ms;
    transform: translateY(-50%);
    user-select: none;
  
    @media (hover: hover) {
      &:hover {
        box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.invertedBackground};
      }
    }
  
    @media ${breakpoints.phone_only} {
      display: none;
    }
  
    @media ${breakpoints.tablet_below} {
      transform: translateY(0);
      top: auto;
      bottom: 16px;
    }
`

const NavigationArrowLeft = styled.div`
    left: 32px;
    ${navigationArrowStyles};
`

const NavigationArrowRight = styled.div`
    right: 32px;
    ${navigationArrowStyles};
`

const NavigationPills = styled.div`
    position: absolute;
    left: 50%;
    bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translateX(-50%);

    @media ${breakpoints.phone_only} {
        display: none;
    }

    @media ${breakpoints.tablet_below} {
        bottom: 43px;
    }
`

const NavigationPill = styled.div<{ isActive: boolean }>`
    width: 80px;
    height: 10px;
    border-radius: 4px;
    margin: 0 8px;
    cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
    background: ${({ isActive, theme }) =>
        isActive ? theme.colors.accent : theme.colors.background};
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.07);

    @media (hover: hover) {
        &:hover {
            box-shadow: 0 0 0 2px
                ${({ theme }) => theme.colors.invertedBackground};
        }
    }
`
