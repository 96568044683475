import React from 'react'
import { StyledComponent } from 'styled-components'
import {
    flattenEdgesNodes,
    getImageFallbackSrc,
    ImageFilesData,
} from 'modules/core/graphql'
import { graphql, useStaticQuery } from 'gatsby'
import clients from 'content/clients.yaml'
import { LocaleConfig } from 'modules/core'

export interface ClientImagesData {
    allFile: ImageFilesData
}

interface ClientListProps {
    ClientComponent: StyledComponent<'img', any>
    locale: LocaleConfig
}

export const ClientList = ({ ClientComponent, locale }: ClientListProps) => {
    const images = flattenEdgesNodes(
        useStaticQuery<ClientImagesData>(graphql`
            query ClientsQuery {
                allFile(filter: { relativePath: { glob: "clients/*.png" } }) {
                    edges {
                        node {
                            name
                            extension
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FIXED
                                    width: 500
                                    quality: 100
                                )
                            }
                        }
                    }
                }
            }
        `).allFile
    )

    const clientsWithImage = clients.map((client) => ({
        ...client,
        alt: client.alt[locale.locale],
        image: getImageFallbackSrc(
            images.find(
                ({ name, extension }) => client.image === `${name}.${extension}`
            )!
        ),
    }))

    return (
        <>
            {clientsWithImage.map((client) => (
                <ClientComponent
                    key={client.name}
                    src={client.image}
                    alt={client.alt}
                />
            ))}
        </>
    )
}
