import React from 'react'
import styled from 'styled-components'
import {
    Container,
    ContentSpacer,
    LocaleConfig,
    PageSubTitle,
} from 'modules/core'
import { LatestPost } from '../blog'
import { useScrollAnalytics } from 'modules/analytics/useScrollAnalytics'

interface BlogProps {
    locale: LocaleConfig
    title: string
    content: string
}

export const Blog = ({ locale, title, content }: BlogProps) => {
    const visibleRef = useScrollAnalytics('home_Blog')

    return (
        <div ref={visibleRef}>
            <Container>
                <PageSubTitle>{title}</PageSubTitle>
                <ContentSpacer />
                <Content
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
                <ContentSpacer size="small" />
                <LatestPost locale={locale} />
            </Container>
        </div>
    )
}

const Content = styled.div``
