import React from 'react'
import styled from 'styled-components'
import {
    Container,
    ContentSpacer,
    LocaleConfig,
    PageSubTitle,
} from 'modules/core'
import { ClientGrid } from 'modules/clients/ClientGrid'

interface ClientsProps {
    locale: LocaleConfig
    title: string
    content: string
}

export const Clients = ({ locale, title, content }: ClientsProps) => {
    return (
        <Container>
            <PageSubTitle>{title}</PageSubTitle>
            <ContentSpacer />
            <Content
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
            <ClientGrid locale={locale} data-testid="clients-clientlist" />
        </Container>
    )
}

const Content = styled.div``
